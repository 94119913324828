import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createDepartment(newDepartmentForm) {
        await Csrf.getCookie();
        return Api.post('/department/create', newDepartmentForm);
    },

    async getAllDepartments() {
        await Csrf.getCookie();
        return Api.get('/departments');
    },

    async getDepartments(institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/filter-all-departments/'+ institute_id);
    },

    async getAllFiltersDepartments(institute_id = null) {
        await Csrf.getCookie();
        return Api.get('/all-departments/filters/'+ institute_id);
    },

    async getAllDepartmentsByFacultyID(faculty_id) {
        await Csrf.getCookie();
        return Api.get('/departments/faculty/' + faculty_id);
    },

    async getAllHeadOfDepartment(faculty_id) {
        await Csrf.getCookie();
        return Api.get('/hod/faculty/' + faculty_id);
    },

    async updateDepartment(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/department/update/' + id, updateData);
    },

    async deleteDepartment(id) {
        await Csrf.getCookie();
        return Api.patch('/department/destroy/' + id);
    },

    async getDepartmentCount() {
        await Csrf.getCookie();
        return Api.get('/count-total-departments');
    },

    async getHODDashboardCountsByDepartmentID(id) {
        await Csrf.getCookie();
        return Api.get('/department/hod/dashboard/counts/' + id);
    },

    async getMyFaculty(id) {
        await Csrf.getCookie();
        return Api.get('/department/myFaculty/' + id);
    },

    async getHOD(id) {
        await Csrf.getCookie();
        return Api.get('/department/hod/' + id);
    },

    async getCurrentHeadOfDepartment(id) {
        await Csrf.getCookie();
        return Api.get('/department/current-hod/' + id);
    },

    async getDepartmentDetails(id) {
        await Csrf.getCookie();
        return Api.get('/department/details/' + id);
    },

    async setHOD(id, appointHODForm) {
        await Csrf.getCookie();
        // return Api.patch('/department/' + deptID + 'hod/' + newHodID);
        return Api.patch('/department/newHod/' + id, appointHODForm);
    },
    
    async deleteHOD(id) {
        await Csrf.getCookie();
        return Api.patch('/department/hod-delete/' + id);
    },

    async getDepartmentsInFaculty(id) {
        await Csrf.getCookie();
        return Api.get('/allDepartment/faculty/' + id);
    },

    async loadPage(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/departments/load-page/' + per_page + '?page=' + page);
    },

    async getStudentResults(data) {
        await Csrf.getCookie();
        return Api.post('/department/student/results', data);
    },

    async downloadStudentResults(data) {
        await Csrf.getCookie();
        return Api.post('/department/student/results-download', data, {responseType: 'blob'});
    },

    async getExamOfficers(id) {
        await Csrf.getCookie();
        return Api.get('/department/exam-officers/' + id);
    },

    async getCurrentExamOfficer(id) {
        await Csrf.getCookie();
        return Api.get('/department/current-exam-officer/' + id);
    },

    async appointExamOfficer(appointHODForm) {
        await Csrf.getCookie();
        return Api.patch('/department/appointExamOfficer', appointHODForm);
    },
    
};